import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";
import getSrc from '@utils/get-root'
import getSchemas from '@utils/get-schemas'

const getImageSrc = (image, root) => (image && image.url) ? getSrc(image.url, root) : null

const Seo = ({ seo, lang, meta, image: metaImage }) => {
    const { site, identity } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
                keywords
                siteUrl
              }
            }
            identity: sanityIdentity {
              seo {
                title
              }
              logo {
                asset {
                  fixed(width: 32) {
                      ...GatsbySanityImageFixed
                  }
                }
              }
            }
          }
        `
      )
    
    const image = seo ? getImageSrc(seo.image, site.siteMetadata.siteUrl) : null
    const keywords = seo && seo.keywords.length > 0 ? seo.keywords : site.siteMetadata.keywords

    let links = [
      { rel: 'icon', href: identity.logo.asset.fixed.src }
    ]
/*
    if (site.siteMetadata.snipcart) {
      const snipcartLinks = [
        { rel: 'preconnect', href: 'https://app.snipcart.com' },
        { rel: 'preconnect', href: 'https://cdn.snipcart.com' },
        { rel: 'stylesheet', href: 'https://cdn.snipcart.com/themes/v3.0.29/default/snipcart.css' },
      ]
      links = [...links, ...snipcartLinks]
    }
*/

    

    const schemaOrgJSONLD = seo ? getSchemas(seo) : false

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={seo ? seo.title : ''}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            link={links}
            meta={[
                {
                    name: `description`,
                    content: seo ? seo.description : '',
                },
                {
                    name: "keywords",
                    content: keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: seo ? seo.title : '',
                },
                {
                    property: `og:description`,
                    content: seo ? seo.description : '',
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: seo ? seo.title : '',
                },
                {
                    name: `twitter:description`,
                    content: seo ? seo.description : '',
                }
            ]        
            .concat(
                metaImage
                  ? [
                      {
                        property: "og:image",
                        content: image,
                      },
                      {
                        property: "og:image:width",
                        content: metaImage.metadata.dimensions.width,
                      },
                      {
                        property: "og:image:height",
                        content: metaImage.metadata.dimensions.height,
                      },
                      {
                        name: "twitter:card",
                        content: "summary_large_image",
                      },
                    ]
                  : [
                      {
                        name: "twitter:card",
                        content: "summary",
                      },
                    ]
              )
              .concat(meta)}
        >
          {schemaOrgJSONLD && <script type="application/ld+json">
            {JSON.stringify(schemaOrgJSONLD)}
          </script>}
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet"/>
        </Helmet>
      )
}

Seo.defaultProps = {
    lang: `fr`,
    meta: [],
    description: ``,
}

export default Seo