export default ({
    title,
    resume,
    cover,
    seo,
    url
}, typeElement) => {
    const finalTitle = seo && seo.title !== undefined ? seo.title : title
    const finalDescription = seo && seo.description !== undefined ? seo.description : resume
    const keywords = seo && seo.keywords !== undefined ? seo.keywords : []
    const image = cover && cover.asset ? cover.asset.url : false

    return {
        title: finalTitle,
        description: finalDescription,
        keywords,
        image,
        typeElement,
        url
    }
}