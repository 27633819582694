const WEBSITE = 'website'
const ARTICLE = 'article'
const PAGE = 'page'
const LISTING = 'listing'

export {
    WEBSITE,
    ARTICLE,
    PAGE,
    LISTING
}


export default ({
    title,
    url,
    typeElement
}) => {
    if (typeElement === WEBSITE) {
        return {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            url,
            name: title
        }
    }

    return false
}